import React from 'react'
import PropTypes from 'prop-types'

const EmbedPhoto = (props) => {
  const { embed } = props

  return (
    <img
      src={embed.url}
      alt={embed.title}
      width={embed.width}
      height={embed.height}
      loading='lazy'
      className='mx-auto max-w-full'
    />
  )
}

EmbedPhoto.propTypes = {
  embed: PropTypes.object.isRequired
}

export default EmbedPhoto
