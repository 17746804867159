import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

/**
 * Rounded `pill` style element, similar to the classic bootstrap style:
 * https://getbootstrap.com/docs/4.0/components/navs/#pills
 * Not used in the nav though, don't be confused by the docs linked above. This
 * is just a name to describe how it looks.
 * @param  {Object} props
 * @return {Element}
 */
const Pill = (props) => {
  const { color, children, className, element: Element, fontWeightClass } = props

  let colorSchemeClassName
  switch (color) {
    case 'primary':
      colorSchemeClassName = 'bg-primary text-white'
      break

    case 'secondary':
      colorSchemeClassName = 'bg-secondary-light text-black'
      break
  }

  return (
    <Element
      className={classNames('inline-block text-xs uppercase tracking-wider rounded py-1 px-2', className, colorSchemeClassName, fontWeightClass)}
    >
      {children}
    </Element>
  )
}

Pill.propTypes = {
  children: PropTypes.string.isRequired,
  className: PropTypes.string,
  color: PropTypes.string,
  element: PropTypes.string,
  fontWeightClass: PropTypes.string
}

Pill.defaultProps = {
  color: 'secondary',
  element: 'p',
  fontWeightClass: 'font-medium'
}

export default Pill
