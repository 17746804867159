import React from 'react'
import PropTypes from 'prop-types'

const EmbedPodcast = (props) => {
  const { embed } = props
  return (
    <iframe
      src={embed.embed_url}
      width='100%'
      height='200'
      scrolling='no'
      title={`${embed.title} Podcast Player`}
      frameBorder='0'
    />
  )
}

EmbedPodcast.propTypes = {
  embed: PropTypes.object.isRequired
}

export default EmbedPodcast
