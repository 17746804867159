import React from 'react'
import PropTypes from 'prop-types'

// Generic
import HtmlContent from '../HtmlContent/HtmlContent'

const EmbedRich = (props) => {
  const { embed } = props

  return (
    <HtmlContent
      html={embed.html}
      className='max-w-lg mx-auto'
    />
  )
}

EmbedRich.propTypes = {
  embed: PropTypes.object.isRequired,
  thumbnail: PropTypes.object,
  className: PropTypes.string,
  imageClassName: PropTypes.string,
  title: PropTypes.string
}

export default EmbedRich
