import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

/**
 * This component creates a "slanted edge" which goes before or after a component
 * @param  {Object} props
 * @return {Element}
 */
const Edge = (props) => {
  const { location, direction, outerClassName, innerClassName } = props

  return (
    <div
      className={classNames('relative bg-current', outerClassName, {
        'pt-7vw': location === 'top',
        'pb-7vw': location === 'bottom'
      })}
    >
      <span
        className={classNames('block absolute left-0 w-full bg-current h-7vw', innerClassName, {
          'clip-path-top-right': (direction === 'right' && location === 'top'),
          'clip-path-bottom-right': (direction === 'right' && location === 'bottom'),
          'clip-path-top-left': (direction === 'left' && location === 'top'),
          'clip-path-bottom-left': (direction === 'left' && location === 'bottom'),
          'top-0': (location === 'top'),
          'bottom-0': (location === 'bottom')
        })}
      />
    </div>
  )
}

Edge.propTypes = {
  location: PropTypes.oneOf(['top', 'bottom']).isRequired,
  direction: PropTypes.oneOf(['right', 'left']).isRequired,
  outerClassName: PropTypes.string.isRequired,
  innerClassName: PropTypes.string.isRequired
}

export default Edge
